import { useAuthState } from "@store/hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const parseJwt = (token) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = ({ logOut }) => {
  const [logoutRun, setLogoutRun] = useState(null);
  const {
    userData: { token },
    isLoggedIn,
  } = useAuthState((state) => state);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn && logoutRun === "enable") {
      setLogoutRun(null);
      navigate("/login");
    }
  }, [logoutRun, isLoggedIn]);

  const checkLoggedInUser = () => {
    const tk = token || "";
    if (tk && isLoggedIn) {
      const decodedJwt = parseJwt(tk);
      if (decodedJwt.exp * 1000 < Date.now()) {
        logOut({ redirect: false });
        setLogoutRun("enable");
      }
    }
  };

  useEffect(() => {
    checkLoggedInUser();
  }, [location, token, isLoggedIn]);

  return <div />;
};

export default AuthVerify;
