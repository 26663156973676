import { useTranslation } from "react-i18next";

const NavBarItem = ({ classes, menuTitle, handleClick }) => {
  const { t } = useTranslation();
  return (
    <li>
      <div className={`px-2.5 py-4 xl:py-2 flex items-center menuHover ${classes}`} onClick={handleClick}>
        {t(menuTitle)}
      </div>
    </li>
  );
};

export default NavBarItem;
