import { Document, EditPencil, Envelope, Heart, LoginIcon, QueueList, RegisterUser } from "@components/images";
import { BUY_WITH_US, PROPERTY_SEARCH, REGISTER, SUPPORT } from "@constants";

export const loggedInUserMenu = [
  {
    id: "requests",
    path: "/my-area/requests",
    classes: "",
    menuTitle: "my_requests.title",
    icon: QueueList,
  },
  {
    id: "messages",
    path: "/my-area/messages",
    classes: "",
    menuTitle: "my_messages.title",
    icon: Envelope,
  },
  {
    id: "documents",
    path: "/my-area/my-documents",
    classes: "",
    menuTitle: "my_documents.title",
    icon: Document,
  },
  {
    id: "watchlist",
    path: "/my-area/watchlist",
    classes: "border-gray-400 border-b-1 border-bottom",
    menuTitle: "my_watchlist.link",
    icon: Heart,
  },
  {
    id: "edit-profile",
    path: "/my-area/edit-profile",
    classes: "border-gray-400 border-b-1",
    menuTitle: "edit_profile.link",
    icon: EditPencil,
  },
];

export const notLoggedInUserMenu = [
  {
    id: "register",
    path: REGISTER,
    classes: "",
    menuTitle: "register.link",
    icon: RegisterUser,
  },
  {
    id: "login",
    path: "/login",
    classes: "border-gray-400 border-b-1 border-bottom",
    menuTitle: "login.link",
    icon: LoginIcon,
  },
];

export const navbarMenu = [
  {
    id: "property-search",
    path: PROPERTY_SEARCH,
    classes: "",
    menuTitle: "search.nav_label",
  },
  {
    id: "immobili-in-asta",
    path: "/immobili-in-asta",
    classes: "",
    menuTitle: "search.nav_court_label",
  },
  {
    id: "chi-siamo",
    path: "/chi-siamo",
    classes: "",
    menuTitle: "about_us.nav_label",
  },
  {
    id: "how-it-works",
    path: BUY_WITH_US,
    classes: "",
    menuTitle: "how_it_works.nav_label",
  },
  {
    id: "support",
    path: SUPPORT,
    classes: "",
    menuTitle: "service_apa.nav_label",
  },
];
