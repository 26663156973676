import { CASES } from "@constants";
import { auctionCountdownCounter, computeAvailabilityAndColor } from "@utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const sharedClass = `h-14 mt-2 sm:mt-0 tracking-widest w-32 flex justify-evenly
items-center text-center uppercase text-white font-bnnp-bold p-2`;

const AvailabilityBadge = ({ assetStateTag, courtAuctionDate, isInCourtAuction }) => {
  const isListing = assetStateTag === CASES.LISTING;
  const countdownCounter = isListing && !!courtAuctionDate && !!isInCourtAuction;
  const { t } = useTranslation();

  // compute badge status
  const badgeStatus = useMemo(
    () => (countdownCounter ? auctionCountdownCounter(courtAuctionDate) : computeAvailabilityAndColor(assetStateTag)),
    [courtAuctionDate, assetStateTag, auctionCountdownCounter, t]
  );

  // define shared classNames and style
  const sharedStyle = { backgroundColor: `${badgeStatus.color}` };

  return countdownCounter && Number(badgeStatus?.days) && badgeStatus?.days > 0 ? (
    // Listing Case for is_court_auction === true
    <div className={`${sharedClass}`} style={sharedStyle}>
      <p className="text-4xl font-bnnp-bold px-1">{badgeStatus?.days?.toString().padStart(2, "0")}</p>
      <p className="break-words text-sm font-bnnp-bold">{badgeStatus.availability}</p>
    </div>
  ) : (
    // Rest cases
    <p className={`${sharedClass} text-lg`} style={sharedStyle}>
      {badgeStatus.availability}
    </p>
  );
};

export default AvailabilityBadge;
