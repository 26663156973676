import { t } from "i18next";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

const MoreProperties = ({ morePropertiesNumber }) => (
  <div className="col-span-3">
    <p>
      <span className="text-greenColor flex items-center justify-center">
        {`${morePropertiesNumber} ${t("search.more_propeties_same_location.label")}`}
      </span>
    </p>
  </div>
);

const NavigationButton = ({ position, clickHandler, children }) => (
  <div
    className="absolute flex items-center justify-center py-1 px-1 cursor-pointer text-greenColor"
    style={position}
    onClick={clickHandler}
  >
    {children}
  </div>
);

const NavigationControl = ({ changePropertyForward, changePropertyBackward, morePropertiesNumber }) => (
  <div className="grid grid-cols-3">
    <NavigationButton position={{ right: "1%" }} clickHandler={changePropertyForward}>
      <AiFillCaretRight />
    </NavigationButton>
    <MoreProperties morePropertiesNumber={morePropertiesNumber} />
    <NavigationButton position={{ left: "1%" }} clickHandler={changePropertyBackward}>
      <AiFillCaretLeft />
    </NavigationButton>
  </div>
);

export { NavigationControl };
