import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone, countryCode) => {
  try {
    return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput(phone, countryCode), countryCode);
  } catch (error) {
    return false;
  }
};

export default isPhoneValid;
