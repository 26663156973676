import { assetsApi } from "../api/assetsApi";
import { mapAssetsApi } from "../api/mapAssetsApi";
import authReducer from "./auth";
import propertyDetailsReducer from "./propertyDetails";
import propertyTypesCodesReducer from "./propertyTypesCodes";
import searchReducer from "./search";

export const rootReducers = {
  assetsApi: assetsApi.reducer,
  mapAssetsApi: mapAssetsApi.reducer,
  auth: authReducer,
  search: searchReducer,
  propertyTypesCodes: propertyTypesCodesReducer,
  propertyDetails: propertyDetailsReducer,
};

const createReducer = {
  ...rootReducers,
};

export default createReducer;
