/**
 * Find special charachter `\n` (new line) in a text and split the
 * text in such a way that will return an array that will add empty string instead of 2 `\n\n`.
 * e.g. "hello\nhere" => ['hello', 'here']
 * e.g "Hi\n\nMy name is\nMarkous" => ['Hi', '', 'My name is', 'Markous']
 *
 * @param {string} message - the message user wrote in textarea (messenger component)
 */
const calculateNewLineInText = (message) => {
  if (!message || message === "") return [];

  const messageSplitted = message.split("\n");
  let acc = 0;
  let i = 0;
  const newMessageSplitted = [];
  while (i < messageSplitted.length) {
    // Means that we have 2 '\n\n' in the row, or 4, 6, 8... etc
    if (messageSplitted[i] === "") {
      let j = i;
      while (messageSplitted[j] === "") {
        acc += 1;
        j += 1;
      }
      const halfSizeArray = acc / 2; // it is always even;
      for (let k = 0; k < halfSizeArray; k++) {
        newMessageSplitted.push(messageSplitted[i + k]);
      }
      // Reset accumulator
      acc = 0;
      // continue the main while from var j
      i = j;
    } else {
      newMessageSplitted.push(messageSplitted[i]);
      i += 1;
    }
  }
  return newMessageSplitted;
};

export default calculateNewLineInText;
