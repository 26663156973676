import { CASES } from "@constants";
import { t } from "i18next";

export const computeAvailabilityAndColor = (key) => {
  const greyColor = "#333333";

  switch (key) {
    case CASES.LISTING:
      return {
        availability: t("listing.banner"),
        color: "#000080",
      };
    case CASES.UNDER_OFFER:
      return {
        availability: t("under_offer.banner"),
        color: "orange",
      };
    case CASES.SOLD:
      return { availability: t("sold.banner"), color: "brown" };
    case CASES.AVAILABLE_TO_BUY_NOW:
      return {
        availability: t("buy_now.available.banner"),
        color: greyColor,
      };
    case CASES.UPCOMING_BUY_NOW:
      return {
        availability: t("buy_now.upcoming.banner"),
        color: greyColor,
      };
    case CASES.AUCTION_RUNNING:
      return {
        availability: t("auction.running.banner"),
        color: greyColor,
      };
    case CASES.UPCOMING_AUCTION:
      return {
        availability: t("auction.upcoming.banner"),
        color: greyColor,
      };
    default:
      return { availability: null, color: null };
  }
};
