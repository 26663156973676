/* eslint-disable react/jsx-curly-newline */
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from "@react-google-maps/api";
// import { useAuthState } from "@store/hooks";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
// import useGenericError from "../../hooks/useGenericError";
/// import { useSearchPropertiesDispatchers } from "../../store/dispatchers";
import PropCard from "./PropCard/PropCard";

const mapContainerStyle = {
  position: "relative",
  height: "100%",
  width: "91%",
  right: "5%",
};

const initialCenter = { lat: 41.8719, lng: 12.5674 };

function GoogleMaps({ assets, showMap, onClose = () => {}, displayMap, inCourtAuction }) {
  const [showPropCard, setShowPropCard] = useState(false);
  const [zoom] = useState(6);
  const [bounds, setBounds] = useState(null);
  const [markerId, setMarkerId] = useState(null);
  const [samePropertiesObj, setSamePropertiesObj] = useState({});

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });

  useEffect(() => {
    if (assets) {
      loadPinsOnMap(assets);
    }
    // eslint-disable-next-line
  }, [assets]);

  const loadPinsOnMap = (assets) => {
    if (assets !== undefined) {
      const obj = assets.reduce((acc, point, i) => {
        const roundLatitude = Math.round((point.latitude + Number.EPSILON) * 100000) / 100000;
        const roundLongitude = Math.round((point.longitude + Number.EPSILON) * 100000) / 100000;
        if (!acc[`${roundLatitude}-${roundLongitude}`]) {
          return {
            ...acc,
            [`${roundLatitude}-${roundLongitude}`]: [{ value: point, id: i }],
          };
        }
        acc[`${roundLatitude}-${roundLongitude}`].push({
          value: point,
          id: i,
        });
        return acc;
      }, {});

      const formattedObj = {};

      Object.keys(obj).forEach((k) => {
        obj[k].forEach((d) => {
          formattedObj[d.id] = obj[k];
        });
      });
      setSamePropertiesObj(formattedObj);
    }
  };

  const onMarkerClick = () => {
    setShowPropCard(true);
  };

  const onCrossPropCardClick = () => {
    setShowPropCard(false);
  };

  const mapLoadHandler = (map) => {
    const { google } = window;
    const points = assets.reduce((acc, point) => {
      if (point.latitude && point.longitude) {
        return [...acc, { lat: point.latitude, lng: point.longitude }];
      }
      return acc;
    }, []);

    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }
    map.fitBounds(bounds);
    setBounds(bounds);
  };

  const defaultMapOptions = {
    fullscreenControl: false,
  };

  return (
    <div>
      {isLoaded && showMap && assets.length > 0 ? (
        <div
          id="modal"
          className="flex fixed bg-black bg-opacity-90 z-30 top-0 bottom-0 right-0 left-0 flex-row-reverse"
        >
          <div
            className="text-white cursor-pointer z-10 text-xl map-close sm:text-2xl"
            onClick={() => {
              onCrossPropCardClick();
              onClose();
            }}
          >
            <AiOutlineClose />
          </div>
          {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}> */}
          <GoogleMap
            onLoad={(map) => {
              return assets.length > 1 ? mapLoadHandler(map) : null;
            }}
            options={defaultMapOptions}
            bounds={bounds}
            mapContainerStyle={mapContainerStyle}
            zoom={assets.length > 1 ? zoom : 19}
            center={
              assets.length > 1
                ? initialCenter
                : {
                    lat: assets?.[0]?.latitude,
                    lng: assets?.[0]?.longitude,
                  }
            }
          >
            <MarkerClusterer maxZoom={15}>
              {(clusterer) =>
                assets.map((point, i) => {
                  if (point.latitude && point.longitude) {
                    return (
                      <Marker
                        options={{
                          fillColor: "green",
                          transform: `translate(${point.latitude},${point.longitude})`,
                        }}
                        key={point.id}
                        position={{
                          lat: Math.round((point.latitude + Number.EPSILON) * 100000) / 100000,
                          lng: Math.round((point.longitude + Number.EPSILON) * 100000) / 100000,
                        }}
                        clusterer={clusterer}
                        icon={{
                          path: "M 0,0 C -4,-20 -13,-22 -13,-30 A 13,13 0 1,1 13,-30 C 13,-22 4,-20 0,0 z",
                          strokeWeight: 2,
                          fillColor: "#d9aa0f",
                          fillOpacity: 1,
                          labelOrigin: { x: 0, y: -30 },
                        }}
                        label={
                          samePropertiesObj[i]?.length > 1
                            ? {
                                text: samePropertiesObj[i].length.toString(),
                                color: "white",
                              }
                            : { text: ".", color: "#d9aa0f" }
                        }
                        clickable
                        onClick={() => {
                          setMarkerId(i);
                          onMarkerClick();
                        }}
                      />
                    );
                  }
                  return null;
                })
              }
            </MarkerClusterer>
            {showPropCard && (
              <PropCard
                data={assets[markerId]}
                arr={samePropertiesObj[markerId]}
                onCrossPropCardClick={onCrossPropCardClick}
                closeMap={displayMap}
                inCourtAuction={inCourtAuction}
              />
            )}
          </GoogleMap>
          {/* </LoadScript> */}
        </div>
      ) : null}
    </div>
  );
}

export default GoogleMaps;
