const getReduxHeaders = (getState) => {
  const {
    userData: { token },
    languageToTranslate,
  } = getState().auth;

  const actualLang = languageToTranslate === "it" ? "lc" : languageToTranslate;
  const header = { lang: actualLang };
  const auth = token ? { auth: token } : {};

  return { ...header, ...auth };
};

export default getReduxHeaders;
