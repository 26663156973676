import ProtectedRoute from "@components/common/ProtectedRoute";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import mainRoutes from "./main-routes";
import myAreaRoutes from "./my-area-routes";

const componentLoader = (componentPath, Component, routeProps) => {
  return componentPath
    ? {
        lazy: async () => {
          const Comp = await import(`../${componentPath}`);
          const FetchedComponent = Comp.default;
          return {
            element: (
              <ProtectedRoute {...routeProps}>
                <FetchedComponent />
              </ProtectedRoute>
            ),
          };
        },
      }
    : {
        element: (
          <ProtectedRoute {...routeProps}>
            <Component />
          </ProtectedRoute>
        ),
      };
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: mainRoutes.map(({ path, componentPath, component: Comp, ...routeProps }) => {
      const loadedComp = componentLoader(componentPath, Comp, routeProps);

      return {
        ...loadedComp,
        path,
        children:
          path === "/my-area"
            ? myAreaRoutes.map(({ path: childPath, componentPath, component: ChildComp, ...rtProps }) => {
                const innerLoadedComp = componentLoader(componentPath, ChildComp, routeProps);

                return childPath === "/my-area"
                  ? {
                      index: true,
                      ...innerLoadedComp,
                    }
                  : { path: childPath, ...innerLoadedComp };
              })
            : [],
      };
    }),
  },
]);

export default router;
