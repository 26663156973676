/* eslint-disable no-nested-ternary */
import {
  bathrooms as bathroomsIcon,
  bed,
  buildArea as buildAreaIcon,
  plotArea as plotAreaIcon,
  rooms as roomsIcon,
  unitNumber,
} from "@components/images";
import { useTranslation } from "react-i18next";

export const IconItem = ({ img, title, item, children, classes }) => (
  <li className="flex mb-2 datas-center">
    <img src={img} alt={title} className={classes} />
    <p>
      <span className="text-greenColor">{item}</span>
      {children}
    </p>
  </li>
);

export const PropInfoIcons = ({ build_area: buildArea, units, bedrooms, rooms, bathrooms, plot_area: plotArea }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap justify-between w-full" style={{ marginTop: "5%" }}>
      {buildArea && buildArea > 0 && (
        <>
          <IconItem
            img={buildAreaIcon}
            title="Build Area"
            item={String(buildArea).replace(".", ",")}
            classes="mr-3 w-6 text-greenColor"
          >
            <span className="text-greenColor">{` ${t("square_meters")}`}</span>
          </IconItem>

          {units && units > 0 && <IconItem img={unitNumber} title="Unit" item={units} classes="mr-3 w-6" />}

          {bedrooms && bedrooms > 0 && !units && <IconItem img={bed} title="Bed" item={bedrooms} classes="mr-3 w-6" />}

          {rooms && rooms > 0 && !units && !bedrooms && (
            <IconItem img={roomsIcon} title="Rooms" item={rooms} classes="mr-3 w-6" />
          )}

          {bathrooms && bathrooms > 0 && units && units === 0 && ((bedrooms && bedrooms > 0) || rooms) && (
            <IconItem img={bathroomsIcon} title="Bathrooms" item={bathrooms} classes="mr-3" />
          )}
        </>
      )}

      {!buildArea && plotArea && plotArea > 0 && (
        <IconItem img={plotAreaIcon} title="Plot Area" item={String(plotArea).replace(".", ",")} classes="mr-3 h-6" />
      )}
    </div>
  );
};
