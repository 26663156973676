import { API_ENDPOINT } from "@constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getReduxHeaders from "@store/helpers/getReduxHeaders";

export const mapAssetsApi = createApi({
  reducerPath: "mapAssetsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT?.replace("v1/", ""), // "https://sales.bnlimmobili.it/api/"
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    prepareHeaders: (headers, { getState }) => {
      const getHeaders = getReduxHeaders(getState);
      Object.keys(getHeaders).forEach((key) => {
        headers.set(key, getHeaders[key]);
      });
      return headers;
    },
  }),
  tagTypes: ["Map-assets"],
  endpoints: (builder) => ({
    getMapAssets: builder.query({
      query: (filters) => ({
        url: `v2/map_assets`,
        body: filters,
      }),
      merge: (currentCache, newItems) => {
        const cachedArray = JSON.parse(JSON.stringify(currentCache.data));
        return currentCache
          ? [...cachedArray, ...newItems.data.filter((nItem) => !cachedArray.find((cItem) => cItem.id === nItem.id))]
          : newItems.data;
      },
    }),
    getMapAssetCards: builder.query({
      query: (filters) => ({
        url: `v2/map_asset_cards`,
        body: filters,
      }),
      keepUnusedDataFor: 180,
      transformResponse: (response) => {
        return response.data;
      },
      merge: (currentCache, newItems) => {
        const cachedArray = JSON.parse(JSON.stringify(currentCache));
        return currentCache
          ? [...cachedArray, ...newItems.filter((nItem) => !cachedArray.find((cItem) => cItem.id === nItem.id))]
          : newItems;
      },
    }),
  }),
});

export const { useLazyGetMapAssetsQuery, useLazyGetMapAssetCardsQuery } = mapAssetsApi;
