import { useAuthState } from "@store/hooks";

export const PropertyTitle = ({ titleLc, titleEn }) => {
  const { languageToTranslate } = useAuthState(({ languageToTranslate }) => ({
    languageToTranslate,
  }));

  return (
    <div className="flex justify-between pr-2 pb-2 pt-3 font-bold text-xl font-bnnp-sans-condensed uppercase">
      <p className="truncate text-ellipsis">{languageToTranslate === "it" ? titleLc : titleEn || null}</p>
    </div>
  );
};
