const QueueList = () => (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 
    1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
  />
);

export default QueueList;
