import {
  ADVICE_REQUESTS,
  API_ENDPOINT,
  DOCUMENTS,
  LOCATIONS,
  PROPERTY_TYPE_CODES,
  SEARCH_ASSETS,
  USER_CHATS,
  USER_CHAT_ATTACHMENTS,
  USER_CHAT_MESSAGES,
  USER_REQUESTS,
} from "@constants";
import { handleResponse } from "./handleResponse";

const V2_URLS = [
  ADVICE_REQUESTS,
  LOCATIONS,
  SEARCH_ASSETS,
  USER_CHATS,
  USER_CHAT_MESSAGES,
  USER_CHAT_ATTACHMENTS,
  USER_REQUESTS,
  PROPERTY_TYPE_CODES,
  DOCUMENTS,
];

export const issueRequest = (url, options) => {
  const { noNotification, ...fetchOpts } = options;

  const Api = V2_URLS.find((v2Url) => url.includes(v2Url) && !url.includes("/download?asset_id"))
    ? API_ENDPOINT.replace(/v1/g, "v2")
    : API_ENDPOINT;

  const fullUrl = `${Api}${url}`;

  return fetch(fullUrl, { ...fetchOpts }).then((res) => handleResponse(res, noNotification));
};
