import { CASES } from "@constants";
import { auctionCountdownCounter, computeAvailabilityAndColor } from "@utils";
import { useMemo } from "react";

export const PropertyImage = ({ src, assetStateTag, onCrossPropCardClick, inCourtAuction, courtAuctionDate }) => {
  const isListing = assetStateTag === CASES.LISTING;
  const countdownCounter = isListing && !!courtAuctionDate && !!inCourtAuction;
  const { availability, color, days } = useMemo(
    () => (countdownCounter ? auctionCountdownCounter(courtAuctionDate) : computeAvailabilityAndColor(assetStateTag)),
    [assetStateTag, courtAuctionDate, inCourtAuction]
  );

  return (
    <>
      <img
        src={src}
        alt="Loading..."
        style={{ height: "auto", maxHeight: "189.75px", width: "100%", maxWidth: "253px" }}
      />
      <div
        className="py-3 text-white text-center tracking-widest font-bold uppercase font-bnnp-sans-condensed text-base"
        style={{
          background: color,
        }}
        onClick={() => {
          onCrossPropCardClick();
        }}
      >
        {`${days ? days?.toString().padStart(2, "0") : ""} `}
        {availability}
      </div>
    </>
  );
};
