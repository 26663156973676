import { AiOutlineClose } from "react-icons/ai";

export const CloseIcon = ({ onCrossPropCardClick }) => (
  <div
    className="absolute flex items-center justify-center py-1 px-1 cursor-pointer"
    style={{ right: "1%", top: "1%" }}
  >
    <AiOutlineClose
      onClick={(e) => {
        e.stopPropagation();
        onCrossPropCardClick();
      }}
    />
  </div>
);
