import { numberWithDots } from "@utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const PriceCalculator = ({ price, classes, decimalClasses }) => {
  const { t } = useTranslation();
  const decimalOfNumber = useMemo(() => (price ? price - Math.floor(price) : null), [price]);

  const numWithCommas = useMemo(
    () => (price ? `${t("currency.label")} ${numberWithDots(price - decimalOfNumber)}` : `${t("currency.label")} 0`),
    [price, decimalOfNumber]
  );

  return (
    <p className={classes}>
      {numWithCommas}
      {decimalOfNumber && decimalOfNumber.toFixed(2) !== "0.00" ? (
        <span className={decimalClasses}>,{decimalOfNumber.toFixed(2).split(".")[1]}</span>
      ) : null}
    </p>
  );
};

export default PriceCalculator;
