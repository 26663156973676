import { numberWithDots } from "@utils";

import { t } from "i18next";

export const PropertyPrice = ({ inCourtAuction, listingPrice, courtAuction }) => {
  return (
    <div className="font-sans text-xs lg:datas-end lg:text-base">
      {inCourtAuction ? (
        <>
          {t("card.court_auction.opening_bid.label")} :
          <span className=" text-greenColor ml-2">
            {courtAuction?.minimum_bid && "€"} {numberWithDots(courtAuction?.minimum_bid)}
          </span>
        </>
      ) : (
        <>
          {t("card.listing.price.label")}:
          <span className=" text-greenColor ml-2">
            {t("currency.label")}
            {numberWithDots(listingPrice)}
          </span>
        </>
      )}
    </div>
  );
};
