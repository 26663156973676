import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

const Links1 = [
  {
    key: "privacy-policy",
    link: "/informativa_privacy_registrazione_sito.pdf",
    label: "privacy_policy.link",
    target: "_blank",
  },
  {
    key: "cookie_policy_link",
    link: "/cookie-policy",
    label: "cookie_policy_link",
  },
  {
    key: "complaints",
    link: "/reclami",
    label: "complaints_link",
  },
];
const Links2 = [
  {
    key: "accessibility",
    link: "/dichiarazione_di_accessibilità_2023.pdf",
    label: "accessibility_link",
    target: "_blank",
  },
  {
    key: "mortgage_policy",
    link: "https://bnl.it/it/Individui-e-Famiglie/Mutui/immobili-in-vendita",
    label: "footer.disclaimer",
    target: "_blank",
  },
  {
    key: "contact",
    link: "/contatti",
    label: "contact_us.nav_label",
  },
];

function CopyRight() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleChangeRoute = (route, target) => () => {
    if (target) {
      const url = route.includes("https") ? route : `${window.location.protocol}//${window.location.hostname}${route}`;
      window.open(url, "_blank");
      return;
    }
    navigate(route);
  };

  const navigateToCrossroad = () => {
    window.open("https://crossroad.app", "_blank");
  };

  return (
    <div className="copy-right-sec bg-black p-10 text-white">
      <div className="container mx-auto">
        <div className="flex copy-inner justify-center">
          <ul className="footer-flex justify-center text-base">
            {Links1.map(({ link, label, key, target }) => (
              <li key={key}>
                <div
                  onClick={handleChangeRoute(link, target)}
                  className="px-3 py-4 lg:py-2 flex items-center menuHover footer-size"
                >
                  {t(label)}
                </div>
              </li>
            ))}
          </ul>
          <ul className="footer-flex justify-center text-base">
            {Links2.map(({ link, label, key, target }) => (
              <li key={key}>
                <div
                  onClick={handleChangeRoute(link, target)}
                  className="px-3 py-4 lg:py-2 flex items-center menuHover footer-size"
                >
                  {t(label)}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <p className="text-sm text-white text-center mt-5 font-Gilroy opacity-50">
          Powered by{" "}
          <span className="cursor-pointer underline" onClick={navigateToCrossroad}>
            Enerinvest{" "}
          </span>
        </p>
      </div>
    </div>
  );
}

export default CopyRight;
