import { Children, useEffect, useState } from "react";
import OutsideAlerter from "./OutsideAlerter";

const DropDown = ({
  disabled,
  toggle,
  hideAfterClick,
  children,
  styles,
  actualHide,
  firstClasses,
  secondClasses,
  enabledPointerEnter = false,
}) => {
  const [show, setShow] = useState(false);

  const toggleDropDown = () => {
    if (disabled) {
      return false;
    }
    toggle(!show);
    // eslint-disable-next-line react/no-access-state-in-setstate
    setShow(!show);
    return true;
  };

  const clickOutside = () => {
    try {
      toggle(false);
    } catch (error) {
      setShow(false);
    }

    setShow(false);
  };

  useEffect(() => {
    if (actualHide) {
      hidePerCase();
    }
  }, [actualHide]);

  const hidePerCase = () => {
    toggle(false);
    setShow(false);
  };

  const hideOnClickInside = (e) => {
    if (hideAfterClick) {
      hidePerCase();
    }
  };

  const data = Children.toArray(children);

  return (
    <OutsideAlerter
      clickOutside={clickOutside}
      styles={`${styles || ""} tooltip-container group relative cursor-pointer`}
    >
      <div
        className={firstClasses}
        onClick={toggleDropDown}
        onPointerEnter={() => (enabledPointerEnter ? toggleDropDown() : {})}
      >
        {data[0]}
      </div>
      {show ? (
        <div className={secondClasses} onClick={hideOnClickInside}>
          {data[1]}
        </div>
      ) : null}
    </OutsideAlerter>
  );
};

export default DropDown;
