import { ENVIRONMENT } from "@constants/process-env";
import { configureStore } from "@reduxjs/toolkit";
import { GetPropertyDetails } from "@services/getPropertyDetails";
import { GetPropertyTypeCodes } from "@services/getPropertyTypeCodes";
import { assetsApi } from "./api/assetsApi";
import { mapAssetsApi } from "./api/mapAssetsApi";
import createReducer from "./reducers";

export function createStore(initialState = {}) {
  const store = configureStore({
    reducer: createReducer,
    preloadedState: initialState,
    devTools: ENVIRONMENT !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: { GetPropertyTypeCodes, GetPropertyDetails },
        },
      }).concat(assetsApi.middleware, mapAssetsApi.middleware),
  });

  return store;
}

const store = createStore();

export default store;
