import { italyFlag } from "@components/images";
import { createSlice } from "@reduxjs/toolkit";

// Grab the logged-in user from localStorage (if there is one)
const userToken = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;
const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
const lang = localStorage.getItem("language");
const flag = localStorage.getItem("flag");

// INIT Authenticate state object
export const initialState = {
  user,
  isLoggedIn: !!userToken,
  userData: userToken || { token: "", userId: "" },
  languageToTranslate: lang || "it",
  flag: flag || italyFlag,
};

const { actions, reducer } = createSlice({
  name: "AUTH",
  initialState,
  reducers: {
    login: (state, action) => {
      const { token, userId, user } = action.payload;
      localStorage.setItem(
        "token",
        JSON.stringify({
          token,
          userId,
        })
      );
      localStorage.setItem("user", JSON.stringify(user));
      state.isLoggedIn = true;
      state.user = user;
      state.userData = {
        token,
        userId,
      };
    },
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      state.isLoggedIn = false;
      state.user = null;
      state.userData = { token: "", userId: "" };
    },
    userUpdate: (state, action) => {
      const getLocalStorageUser = JSON.parse(localStorage.getItem("user"));
      // eslint-disable-next-line camelcase
      const { confirm_email, ...restElements } = action.payload.updatedUser;
      const finalUser = {
        ...getLocalStorageUser,
        ...restElements,
      };
      state.user = finalUser;
      localStorage.setItem("user", JSON.stringify(finalUser));
    },
    setLanguage: (state, action) => {
      localStorage.setItem("language", action.payload);
      state.languageToTranslate = action.payload;
    },
    setFlagToTop: (state, action) => {
      localStorage.setItem("flag", action.payload);
      state.flag = action.payload;
    },
  },
});

export const { login, logout, setLanguage, setFlagToTop, userUpdate } = actions;

export default reducer;
