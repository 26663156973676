import { BroadcastChannel } from "broadcast-channel";

function ExpiredTokenChannel() {
  this.channel = new BroadcastChannel("token-expired");

  this.onMessageReceive = (cb) => {
    this.channel.onmessage = (e) => cb(e);
  };

  this.onSendMessage = (args) => {
    this.channel.postMessage({ ...args });
  };

  this.closeChannel = () => {
    this.channel.close();
  };
}

export default ExpiredTokenChannel;
