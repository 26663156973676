import { BNP, facebook, linkedin } from "@components/images";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const handleChangeRoute = () => {
    navigate("/");
  };

  const navigateFacebook = () => {
    window.open("https://www.facebook.com/BNL-BNP-Paribas-372245919454054", "_blank");
  };

  const navigateLinkedin = () => {
    window.open("https://www.linkedin.com/company/bnl-bnp-paribas/", "_blank");
  };

  return (
    <footer className="container px-3 flex mx-auto fix-nav">
      <div className="container py-8 mx-auto flex items-center justify-between sm:flex-row flex-col ">
        <div
          onClick={handleChangeRoute}
          className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900 menuHover"
        >
          <img src={BNP} alt="Loading..." style={{ width: "221px", height: "61px" }} />
        </div>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start space-x-1 ">
          <img src={facebook} alt="Loading..." className="h-7 w-7 cursor-pointer" onClick={navigateFacebook} />
          <img src={linkedin} alt="Loading..." className="h-7 w-7 cursor-pointer" onClick={navigateLinkedin} />
        </span>
      </div>
    </footer>
  );
}

export default Footer;
