/* eslint-disable import/prefer-default-export */
export const ENVIRONMENT = process.env.NODE_ENV;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const { REACT_SERVER_PORT } = process.env;
export const GOOGLE_SITE_KEY_CAPTCHA = process.env.REACT_APP_API_GOOGLE_SITE_KEY_CAPTCHA || "";
export const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY || "";
export const APA_LIGHT_URL = process.env.REACT_APP_APA_LIGHT_URL || "";
export const APA_FULL_URL = process.env.REACT_APP_APA_FULL_URL || "";
export const KAAJA_KEY = process.env.REACT_APP_KAAJA_KEY || "";
export const KAAJA_URL = process.env.REACT_APP_KAAJA_URL || "";
