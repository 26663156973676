/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable max-len */
import { LanguageDropdown } from "@components/common";
import DropDown from "@components/common/DropDown";
import Toastify from "@components/common/Toastify";
import { ActiveUser, BNP, Gear, Logout, User, hamburgerIcon } from "@components/images";
import { REGISTER } from "@constants";
import { useAuthDispatchers } from "@store/dispatchers";
import { useAuthState } from "@store/hooks";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import NavBarItem from "./NavBarItem";
import NavBarMobileItem from "./NavBarMobileItem";
import { loggedInUserMenu, navbarMenu, notLoggedInUserMenu } from "./helper";

const Navbar = () => {
  const { isLoggedIn, user } = useAuthState((state) => state);
  const [isMobileMenuIcon, setIsMobileMenuIcon] = useState(false);
  const [isToggleMenu, setIsToggleMenu] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [actualHide, setActualHide] = useState(undefined);

  const { handleLogout } = useAuthDispatchers();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const userInitials = useMemo(
    () => `${user?.first_name?.split("")[0]?.toUpperCase()}${user?.last_name?.split("")[0]?.toUpperCase()}`,
    [user?.first_name, user?.last_name]
  );

  const handleInnerLogout = () => {
    handleLogout();
    navigate("/login");
    Toastify(t("sessions.sign_out.banner"), "success");
  };

  useEffect(() => {
    setActualHide(false);
  }, [location?.pathname]);

  useEffect(() => {
    setIsMobileMenuIcon(isToggleMenu);
  }, [isToggleMenu]);

  useEffect(() => {
    const updateDimensions = (event) => {
      setWidth(event.target.innerWidth);
    };

    window.addEventListener("resize", debounce(updateDimensions, 300));
    return () => window.removeEventListener("resize", debounce(updateDimensions, 300));
  }, []);

  const toggleNavbar = () => {
    setIsToggleMenu(!isToggleMenu);
  };

  const hasMenu = useMemo(() => width > 1219 || isToggleMenu, [isToggleMenu, width]);

  const handleChangeRoute = useCallback(
    (route) => () => {
      if (isToggleMenu) {
        setIsToggleMenu(!isToggleMenu);
      }
      setActualHide(true);
      navigate(route);
    },
    [isToggleMenu]
  );

  return (
    <>
      <div className="top-bar-sec bg-gradient-to-r from-green-800 to-green-600 h-1" />
      <nav
        className="container px-3 w-full flex mx-auto flex-wrap items-center justify-between py-3 fix-nav "
        id="navDiv"
      >
        <div className="container mx-auto flex  custom-wrap-flex items-center justify-between ">
          <div
            className="w-full relative flex justify-between xl:w-auto xl:static
          xl:block xl:justify-start items-center"
          >
            <img
              src={BNP}
              alt="Logo"
              style={{
                width: "221px",
                height: "61px",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={handleChangeRoute("/")}
            />
            <div className="flex">
              <div className="xl:hidden">
                <DropDown
                  toggle={(toggle) => {
                    setOpen(toggle);
                  }}
                  actualHide={actualHide}
                >
                  <div>
                    <img className="h-8 w-8 rounded-full" src={open ? ActiveUser : User} alt="User Profile" />
                  </div>
                  <div className="absolute setting-modal bg-white z-30 max-w-xs right-0 top-12 user-menu-dropdown rounded-sm overflow-hidden">
                    {isLoggedIn ? (
                      <>
                        {loggedInUserMenu.map(({ id, path, classes, menuTitle, icon }) => (
                          <NavBarMobileItem
                            key={id}
                            path={path}
                            classes={classes}
                            menuTitle={menuTitle}
                            icon={icon}
                            handleClick={handleChangeRoute(path)}
                          />
                        ))}
                        <NavBarMobileItem
                          path=""
                          classes="border-bottom"
                          menuTitle="logout.button"
                          icon={Logout}
                          handleClick={handleInnerLogout}
                        />
                      </>
                    ) : (
                      <>
                        {notLoggedInUserMenu.map(({ id, path, classes, menuTitle, icon }) => (
                          <NavBarMobileItem
                            key={id}
                            path={path}
                            classes={classes}
                            menuTitle={menuTitle}
                            icon={icon}
                            handleClick={handleChangeRoute(path)}
                          />
                        ))}
                      </>
                    )}
                    <div className="py-2 px-4 flex justify-center items-center gap-2 hover:text-green-700 transition duration-150">
                      <LanguageDropdown fixed />
                    </div>
                  </div>
                </DropDown>
              </div>
              <div>
                <button
                  className="block xl:hidden  cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent  outline-none focus:outline-none"
                  type="button"
                  onClick={toggleNavbar}
                >
                  {isMobileMenuIcon ? (
                    <AiOutlineClose className="close-icon" />
                  ) : (
                    <img src={hamburgerIcon} alt="Expand" />
                  )}
                </button>
              </div>
            </div>
          </div>
          {hasMenu && (
            <div
              className="xl:flex items-center  bg-white xl:bg-transparent xl:shadow-none  space-x-10"
              id="example-collapse-navbar"
            >
              <ul className="xl:flex xl:flex-wrap justify-center items-center text-sm	font-bold">
                {navbarMenu.map(({ id, classes, path, menuTitle }) => (
                  <NavBarItem key={id} menuTitle={menuTitle} handleClick={handleChangeRoute(path)} classes={classes} />
                ))}
              </ul>
            </div>
          )}
          {/* DESKTOP VIEW */}
          <div className="xl:flex hidden text-right  space-x-4 pr-0">
            {/* LANGUAGE SELECTION */}
            <div className="relative inline-block text-left">
              <LanguageDropdown />
            </div>
            {isLoggedIn ? (
              <div className="self-center flex">
                <DropDown toggle={() => {}} hideAfterClick>
                  <div className="image-wrapper cursor-pointer flex justify-center">
                    <p className="self-center text-base text-white font-semibold">{userInitials}</p>
                    <div className="extend-arrow absolute top-0 left-0 opacity-0 hover:opacity-100">
                      <div className="triangle-down right-3 absolute" />
                    </div>
                  </div>
                  <div className="absolute setting-modal bg-white z-30 max-w-xs right-0 top-12 user-menu-dropdown rounded-sm overflow-hidden">
                    <NavBarMobileItem
                      path="/my-area"
                      classes="border-gray-400 border-b-1"
                      menuTitle="my_area.page_title"
                      icon={Gear}
                      handleClick={() => navigate("/my-area")}
                    />
                    <NavBarMobileItem
                      path=""
                      classes=""
                      menuTitle="logout.button"
                      icon={Logout}
                      handleClick={handleInnerLogout}
                    />
                  </div>
                </DropDown>
              </div>
            ) : (
              <>
                <button
                  type="button"
                  onClick={handleChangeRoute(REGISTER)}
                  className="px-7 py-2 tracking-widest bg-bgDarakGreen font-bnnp-bold text-base text-white leading-7 menuHover"
                >
                  {t("register.link")}
                </button>
                <button
                  type="button"
                  onClick={handleChangeRoute("/login")}
                  className="px-8 py-2 tracking-widest font-bnnp-bold text-base text-white bg-greenColor leading-7 menuHover"
                >
                  {t("login.link").toUpperCase()}
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
      <hr />
    </>
  );
};

export default Navbar;
