/* eslint-disable max-len */
import ReactPaginate from "react-paginate";

const pageClassName = "cursor-pointer outline-none inline-flex items-center border text-sm font-medium border-gray-300";
const linkClassName = "px-3 md:px-6 py-2";
const nextPreviousLinkClassName = "px-2 py-2";
const activeClassName =
  "cursor-pointer outline-none bg-greenColor inline-flex items-center border-2 text-sm font-medium border-greenColor text-white";

const Pagination = ({
  totalPages,
  currentPage,
  onPageChange,
  pageClasses = pageClassName,
  breakClasses = pageClassName,
  pageLinkClasses = linkClassName,
  breakLinkClasses = linkClassName,
  nextLinkClasses = nextPreviousLinkClassName,
  previousLinkClasses = nextPreviousLinkClassName,
  activeClasses = activeClassName,
}) => {
  const handlePageChange = (event) => {
    onPageChange(event);
  };

  return (
    <div className="container mx-auto ">
      <div className="flex-1 sm:flex sm:items-center sm:justify-between" style={{ justifyContent: "center" }}>
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageChange}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          pageCount={totalPages}
          forcePage={currentPage - 1}
          previousLabel="<"
          pageClassName={pageClasses}
          pageLinkClassName={pageLinkClasses}
          previousClassName={`
                  "relative inline-flex cursor-pointer items-center -r-md border
                  border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 bg-dropDownColor"
                ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
          previousLinkClassName={previousLinkClasses}
          nextClassName={`"relative cursor-pointer inline-flex items-center
               border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                ${currentPage === totalPages || !totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
          nextLinkClassName={nextLinkClasses}
          breakLabel="..."
          breakClassName={breakClasses}
          breakLinkClassName={breakLinkClasses}
          containerClassName="flex justify-center rounded-md shadow-sm -space-x-px mb-5 font-sans"
          activeClassName={activeClasses}
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};
export default Pagination;
