export const HOME = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const RESET_PASSWORD_URL = "/reset-password";
export const EDIT_PROFILE_URL = "/edit-profile";
export const MY_AREA = "/my-area";
export const MESSAGES = "/messages";
export const PROPERTY_SEARCH = "/immobili-BNL";
export const PROPERTY_SEARCH_ASTA = "/immobili-in-asta";
export const PROPERTY_DETAILS = "/property-details/:propertyId";
export const PROPERTY_DETAILS_SALE = "/property-details-sale/:propertyId";
export const FORGOT_PASSWORD = "/users/password/new";
export const BUY_WITH_US = "/acquista-con-noi";
export const WHO_WE_ARE = "/chi-siamo";
export const CONTACT = "/contatti";
export const MY_OFFER = "/le-mei-offerte";
export const WATCH_LIST = "/watchlist";
export const MY_DOCS = "/my-documents";
export const CONFIRMATION_EMAIL_PAGE_PATH = "/users/confirmation/new";
export const RECLAMI = "/reclami";
export const REQUESTS_URL = "/requests";
export const COOKIE_POLICY = "/cookie-policy";
export const REQUEST_FREE_SUPPORT = "/request-free-support/:propertyId";
export const SUPPORT = "/supporto_in_asta";

export const generatePropertyDetailLink = (id) => {
  return PROPERTY_DETAILS.replace(":propertyId", id);
};

export const generatePropertyDetailsSaleLink = (id) => {
  return PROPERTY_DETAILS_SALE.replace(":propertyId", id);
};
