import { API_ENDPOINT } from "@constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getReduxHeaders from "@store/helpers/getReduxHeaders";

export const assetsApi = createApi({
  reducerPath: "assetsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT?.replace("v1/", ""), //   "https://sales.bnlimmobili.it/api/"
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    prepareHeaders: (headers, { getState }) => {
      const getHeaders = getReduxHeaders(getState);
      Object.keys(getHeaders).forEach((key) => {
        headers.set(key, getHeaders[key]);
      });
      return headers;
    },
  }),
  tagTypes: ["Assets"],
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getAssets: builder.query({
      query: (filters) => ({
        url: `v2/assets`,
        body: filters,
      }),
    }),
  }),
});

export const { useLazyGetAssetsQuery } = assetsApi;
