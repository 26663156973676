import { DAYS, DAYS_IT, MONTHS, MONTHS_IT } from "../constants";

export function dateAndTime(date, lang) {
  const d = new Date(date);
  const romeTime = new Date(d.toLocaleString("en", { timeZone: "Europe/Rome" }));

  const year = romeTime.getFullYear();
  const month = romeTime.getMonth();
  const day = romeTime.getDate();
  const dayOfWeek = romeTime.getDay();
  const hours = romeTime.getHours();
  let minutes = romeTime.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const dateFormatEN = `${DAYS[dayOfWeek]}, ${day} ${MONTHS[month]} ${year}  ${hours}:${minutes}`;
  const dateFormatIT = `${DAYS_IT[dayOfWeek]}, ${day} ${MONTHS_IT[month]} ${year}  ${hours}:${minutes}`;

  return lang === "en" ? dateFormatEN : dateFormatIT;
}
