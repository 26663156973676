import { toast } from "react-toastify";

const options = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  progress: undefined,
};

const Toastify = (message, alertType = "info") => {
  toast[alertType](message, options);
};

export default Toastify;
