import { useTranslation } from "react-i18next";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const ShareLink = ({ title, url }) => {
  const shareUrl = window.location.href;
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center">
      <div className="flex pl-1 pr-1">
        <EmailShareButton url={shareUrl} subject={`${title} | BNLimmobili.it`} body={t("share.email.body")}>
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
      <div className="flex pl-1 pr-1">
        <FacebookShareButton url={url || shareUrl} quote={title}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>
      <div className="flex pl-1 pr-1">
        <TwitterShareButton url={url || shareUrl} title={title}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>
      <div className="flex pl-1 pr-1">
        <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default ShareLink;
