import { createSlice } from "@reduxjs/toolkit";

const initialSearchState = {
  initialLoad: false,
  dirty: false,
  loading: false,
  currentPage: 1,
  selectedPropertyTypesIds: [],
  propertyTypes: [],
  minBid: "",
  maxBid: "",
  minPrice: "",
  maxPrice: "",
  selectedAreas: [],
  mapEnabled: false,
  sortBy: "relevance",
  totalPages: undefined,
  totalResults: undefined,
};

const searchSlice = createSlice({
  name: "searchNew",
  initialState: initialSearchState,
  reducers: {
    setDirty: (state, action) => {
      state.dirty = action.payload;
    },
    setPropertyTypes: (state, action) => {
      state.propertyTypes = action.payload;
    },
    setSelectedPropertyTypesIds: (state, action) => {
      state.selectedPropertyTypesIds = action.payload;
    },
    setSelectedAreas: (state, action) => {
      state.selectedAreas = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setMinBid: (state, action) => {
      state.minBid = action.payload;
    },
    setMaxBid: (state, action) => {
      state.maxBid = action.payload;
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    setMapEnabled: (state, action) => {
      state.mapEnabled = action.payload;
    },
    setOnGettingAssets: (state, action) => {
      const res = action.payload.data;
      state.loading = false;

      state.currentPage = res?.pagination.total < res?.pagination.page ? 1 : res?.pagination.page;

      state.totalPages = res?.pagination.total_pages;
      state.totalResults = res?.pagination.total;
      // state.data = res?.data;
      state.propertyTypes = state.propertyTypes.map((propType) => {
        const availableFilter = res.available_filters.property_types.find((prop) => prop.id === propType.id);
        let assetCount;
        if ((res.filters?.location_ids?.length > 0 && !availableFilter) || !availableFilter) {
          assetCount = 0;
        } else if (availableFilter) {
          assetCount = availableFilter?.assets_count;
        }
        return {
          ...propType,
          isChecked:
            availableFilter?.assets_count > 0 && res.filters.property_type_ids.includes(propType.id) ? "checked" : "",
          disabled:
            (res.filters?.location_ids?.length > 0 && !availableFilter) || !availableFilter
              ? true
              : availableFilter?.assets_count === 0,
          assets_count: assetCount,
        };
      });
      state.initialLoad = action.payload.initialLoad;
    },
    clearAll: (state) => {
      state.propertyTypes = state.propertyTypes.map((pt) => ({
        ...pt,
        isChecked: "",
      }));
      state.selectedPropertyTypesIds = [];
      state.currentPage = 1;
    },
    resetState: () => {
      return { ...initialSearchState };
    },
  },
});

const { actions, reducer } = searchSlice;

export const {
  setDirty,
  setPropertyTypes,
  setSelectedPropertyTypesIds,
  setCurrentPage,
  setTotalPages,
  setSelectedAreas,
  setSortBy,
  setMinBid,
  setMaxBid,
  setMinPrice,
  setMaxPrice,
  setMapEnabled,
  setOnGettingAssets,
  clearAll,
  resetState,
} = actions;

export default reducer;
