import { t } from "i18next";

const countdownColorRules = (days) => {
  let caseValue = "";
  if (days < 0) {
    caseValue = "negative";
  } else if (days === 0 || days === 1) {
    caseValue = days === 0 ? "zero" : "one";
  } else if (days > 1 && days < 15) {
    caseValue = "lessThanFifteen";
  } else if (days >= 15 && days < 30) {
    caseValue = "betweenFifteenAndThirty";
  } else if (days >= 30) {
    caseValue = "greaterThanThirty";
  }

  switch (caseValue) {
    case "negative":
      return {
        availability: t("auction.ended.banner"),
        color: "#000080",
      };
    case "zero":
      return {
        availability: t("today"),
        color: "brown",
      };
    case "one":
    case "lessThanFifteen":
      return {
        availability: t("auction.days_to_auction"),
        color: "brown",
      };
    case "betweenFifteenAndThirty":
      return {
        availability: t("auction.days_to_auction"),
        color: "#ff8b0f",
      };
    case "greaterThanThirty":
      return {
        availability: t("auction.days_to_auction"),
        color: "#000080",
      };
    default:
      return {
        availability: "N/A",
        color: "#000080",
      };
  }
};

const auctionCountdownCounter = (auctionDateString) => {
  const auctionDate = new Date(new Date(auctionDateString).setHours(0, 0, 0, 0));

  const now = new Date().setHours(0, 0, 0, 0);
  const distance = auctionDate.getTime() - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));

  const countdownColorRule = countdownColorRules(days);

  return {
    ...countdownColorRule,
    days,
  };
};

export default auctionCountdownCounter;
