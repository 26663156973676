const Attachment = ({ color }) => (
  <path
    d="M0.779663 7.55372L6.52491 1.80848C8.23345 0.0999334 11.0035 0.099933 12.7121 1.80848C14.4206 3.51702 
    14.4206 6.28712 12.7121 7.99566L6.08296 14.6248C4.94393 15.7638 3.0972 15.7638 1.95817 14.6248C0.819145 
    13.4858 0.819145 11.639 1.95817 10.5L8.5873 3.87087C9.15681 3.30136 10.0802 3.30136 
    10.6497 3.87087C11.2192 4.44039 11.2192 5.36375 10.6497 5.93327L4.90445 11.6785"
    strokeLinecap="round"
    strokeLinejoin="round"
    color={color}
  />
);

export default Attachment;
