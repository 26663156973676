import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  clearAll,
  resetState,
  setCurrentPage,
  setDirty,
  setMapEnabled,
  setMaxBid,
  setMaxPrice,
  setMinBid,
  setMinPrice,
  setOnGettingAssets,
  setPropertyTypes,
  setSelectedAreas,
  setSelectedPropertyTypesIds,
  setSortBy,
  setTotalPages,
} from "../reducers/search";

export default function useSearchDispatchers() {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      handleReset: () => dispatch(resetState()),
      handleDirty: (dirty) => dispatch(setDirty(dirty)),
      handleSetPropertyTypes: (type) => dispatch(setPropertyTypes(type)),
      handleSetSelectedPropertyTypesIds: (input) => dispatch(setSelectedPropertyTypesIds(input)),
      handleSetSelectedAreas: (input) => dispatch(setSelectedAreas(input)),
      handleSetCurrentPage: (page) => dispatch(setCurrentPage(page)),
      handleSetTotalPages: (totalPages) => dispatch(setTotalPages(totalPages)),
      handleClearAll: () => dispatch(clearAll()),
      handleSortBy: (payload) => dispatch(setSortBy(payload)),
      handleMinBid: (payload) => dispatch(setMinBid(payload)),
      handleMaxBid: (payload) => dispatch(setMaxBid(payload)),
      handleMinPrice: (payload) => dispatch(setMinPrice(payload)),
      handleMaxPrice: (payload) => dispatch(setMaxPrice(payload)),
      handleMapEnabled: (payload) => dispatch(setMapEnabled(payload)),
      handleOnGettingAssets: ({ data, initialLoad }) => dispatch(setOnGettingAssets({ data, initialLoad })),
    }),
    [dispatch]
  );
}
