import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { login, logout, setFlagToTop, setLanguage, userUpdate } from "../reducers/auth";

export default function useAuthDispatchers() {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      handleLogin: (value) => dispatch(login(value)),
      handleLogout: () => dispatch(logout()),
      handleFlagToTop: (flag) => dispatch(setFlagToTop(flag)),
      handleLanguageChange: (lang) => dispatch(setLanguage(lang)),
      handleUpdateUser: (value) => dispatch(userUpdate(value)),
    }),
    [dispatch]
  );
}
