export const CASES = {
  LISTING: "LISTING",
  UNDER_OFFER: "UNDER OFFER",
  SOLD: "SOLD",
  AVAILABLE_TO_BUY_NOW: "AVAILABLE TO BUY NOW",
  UPCOMING_BUY_NOW: "UPCOMING BUY NOW",
  AUCTION_RUNNING: "AUCTION RUNNING",
  UPCOMING_AUCTION: "UPCOMING AUCTION",
  AUCTION_ENDED: "AUCTION ENDED",
};
