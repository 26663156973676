/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getReduxHeaders from "@store/helpers/getReduxHeaders";

const initialPropertyTypesCodesState = {
  loading: false,
  propertyTypeCodes: new Map(),
};

export const onLoadPropertyTypeCodes = createAsyncThunk(
  "propertyTypesCodes/onLoadPropertyTypeCodes",
  async (_, { rejectWithValue, extra, getState }) => {
    const headers = getReduxHeaders(getState);
    try {
      const res = await extra.GetPropertyTypeCodes(headers);
      return { res };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const propertyTypesCodesSlice = createSlice({
  name: "propertyTypesCodes",
  initialState: initialPropertyTypesCodesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(onLoadPropertyTypeCodes.pending, (state) => {
        state.loading = true;
      })
      .addCase(onLoadPropertyTypeCodes.fulfilled, (state, action) => {
        const { res } = action.payload;
        const tempMap = new Map();
        res.data.forEach((code) => {
          if (code?.code && code.id) {
            tempMap.set(code.code, code.id);
          }
        });
        state.propertyTypeCodes = tempMap;
        state.loading = false;
      })
      .addCase(onLoadPropertyTypeCodes.rejected, (state) => {
        state.loading = false;
      });
  },
});

const { reducer } = propertyTypesCodesSlice;

export default reducer;
