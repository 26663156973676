/* eslint-disable no-nested-ternary */
import { heartFilled, heartUnfill } from "@components/images";
import { generatePropertyDetailLink, generatePropertyDetailsSaleLink } from "@constants";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AvailabilityBadge from "../AvailabilityBadge";
import PriceCalculator from "../PriceCalculator";
import Description from "./components/Description";
import PropertyDetails from "./components/PropertyDetails";

const ListPropCard = ({
  id,
  title,
  hasAuction,
  query,
  courtAuctionDate,
  mainPhoto,
  isFavourite,
  addToFav,
  assetStateTag,
  description,
  info,
}) => {
  const { t } = useTranslation();

  const pathName = useMemo(
    () => (hasAuction ? generatePropertyDetailLink(id) : generatePropertyDetailsSaleLink(id)),
    [hasAuction]
  );

  return (
    <div className="container mx-auto sm:p-3 md:p-0">
      <div className="mx-auto mb-7 flex flex-col md:flex-row" style={{ border: "1px solid #D6D6D6" }}>
        <div className="lg:w-4/12 md:w-9/12 w-full relative">
          <div
            className={`${
              hasAuction ? "bg-dropDownHoverColor" : "bg-dropDownColor"
            } absolute z-1 font-bnnp-sans-condensed tracking-widest text-2xl top-0 w-full flex
                    items-center justify-center h-12 text-center font-bold uppercase text-white`}
          >
            {hasAuction ? <p>{t("in_court_auction.banner")}</p> : <p>{t("in_direct_sale_banner")}</p>}
          </div>
          <Link
            to={{
              pathname: pathName,
              state: query ? { referrer: query } : {},
            }}
          >
            <img
              src={mainPhoto}
              style={{ height: "330px" }}
              alt="Main Property"
              className={`w-full object-cover ${assetStateTag === "VENDUTO" ? " opacity-50" : ""}`}
            />
          </Link>
          <div
            className="heartIconContainer flex items-center justify-center cursor-pointer bottom-4"
            onClick={() => addToFav(id)}
          >
            <img src={isFavourite ? heartFilled : heartUnfill} alt="add favourite" />
          </div>
        </div>
        <div className="md:w-9/12 w-full p-4 bg-white">
          <h3
            className="font-bold flex text-2xl sm:text-4xl md:px-0 md:py-0
            uppercase text-black leading-8 font-bnnp-bold"
            style={{ justifyContent: "space-between" }}
          >
            <Link
              to={{
                pathname: pathName,
                state: { referrer: query },
              }}
            >
              <span className="truncate-title">{title}</span>
            </Link>

            {info?.listingPrice && !hasAuction ? (
              <PriceCalculator
                price={info.listingPrice}
                classes="font-bold font-bnnp-sans-condensed sm:text-4xl
                        text-3xl text-blackColor price-width-place"
                decimalClasses="text-xl sm:text-3xl"
              />
            ) : null}
            {info?.bid && hasAuction ? (
              <PriceCalculator
                price={info.bid}
                classes="font-bold font-bnnp-sans-condensed sm:text-4xl
                        text-3xl text-blackColor price-width-place"
                decimalClasses="text-xl sm:text-3xl"
              />
            ) : null}
          </h3>
          <div className="flex items-center justify-between md:items-start">
            <AvailabilityBadge
              assetStateTag={assetStateTag}
              isInCourtAuction={hasAuction}
              courtAuctionDate={courtAuctionDate}
            />
          </div>
          <div className="mb-4 pt-4 text-black leading-6 text-justify font-sans text-base truncate-description">
            <Description description={description} pathName={pathName} query={query} />
          </div>
          <PropertyDetails details={info.property} type={info?.propertyTypeName} />
        </div>
      </div>
    </div>
  );
};

export default ListPropCard;
