import { useEffect, useRef, useState } from "react";

const Tooltip = (props) => {
  const divRef = useRef(null);
  const [tooltipStatus, setTooltipStatus] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setTooltipStatus(0);
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  return (
    <div ref={divRef} className="relative" onClick={() => setTooltipStatus(1)}>
      {props.children}
      {tooltipStatus === 1 && (
        <div
          role="tooltip"
          className="tooltip border border-gray-400 z-20 -mt-20 w-42 absolute transition 
          duration-150 ease-in-out right-0 ml-8 shadow-lg bg-white p-2 rounded"
        >
          {props.text}
        </div>
      )}
    </div>
  );
};

Tooltip.displayName = "Tooltip";

export default Tooltip;
