import { dropdownImage } from "@components/images";
import { langOptions } from "@constants";
import { useAuthDispatchers } from "@store/dispatchers";
import { useAuthState } from "@store/hooks";
import { getElementCoordinates } from "@utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DropDown from "./DropDown";

const LanguageDropdown = ({ fixed = false }) => {
  const langRef = useRef();
  const [openedDropDown, setOpenDropDown] = useState(false);
  const [isLanguageIconChange, setIsLanguageIconChange] = useState(false);
  const [extraStyles, setExtraStyles] = useState({});

  const { languageToTranslate, flag } = useAuthState((state) => state);
  const { handleLanguageChange, handleFlagToTop } = useAuthDispatchers();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (fixed && langRef?.current) {
      const styles = getElementCoordinates(langRef?.current);
      setExtraStyles({ left: styles.left - 30, top: styles.top + 42 });
    }
  }, [fixed, langRef?.current]);

  const handleDropDown = () => {
    setIsLanguageIconChange(true);
    setOpenDropDown(!openedDropDown);
  };

  const changeSelectedLanguage = useCallback(
    (lang, flag) => {
      handleLanguageChange(lang);
      setIsLanguageIconChange(false);
      setOpenDropDown(!openedDropDown);

      handleFlagToTop(flag);
      i18n.changeLanguage(lang.toLowerCase());
    },
    [handleLanguageChange, handleFlagToTop, openedDropDown, i18n]
  );

  return (
    <DropDown toggle={() => {}} hideAfterClick>
      <div ref={langRef}>
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md w-18 border-gray-300 shadow-sm
          px-2 py-2 bg-white text-gray-700 hover:bg-gray-50 text-base font-semibold"
          id="menu-button-2"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={handleDropDown}
        >
          <img src={flag} alt="language flag" className="w-5 h-5 mr-2" />
          <span className="uppercase mr-1">{languageToTranslate}</span>
          <img
            className={isLanguageIconChange && openedDropDown ? "isExpand" : ""}
            src={dropdownImage}
            alt="dropDown"
          />
        </button>
      </div>
      <div
        className={`${fixed ? "fixed" : "absolute"} setting-modal bg-white z-30 w-32 right-0 top-12
      user-menu-dropdown rounded-sm`} // overflow-hidden
        style={extraStyles}
      >
        {langOptions.map((value) => {
          return (
            <div
              key={value?.code}
              className="flex justify-start gap-2 items-center py-2 px-4 border-gray-400 border-b-1"
              role="none"
              onClick={() => changeSelectedLanguage(value.code, value.flag)}
            >
              <img src={value.flag} alt={value.country} className="w-5 h-5" />
              <span className="px-2 whitespace-nowrap">{value.country}</span>
            </div>
          );
        })}
      </div>
    </DropDown>
  );
};

export default LanguageDropdown;
