import {
  onLoadPropertyDetails,
  resetState,
  setKaajaWidgetUrl,
  setLoading,
  setMakeOfferDate,
  setMessage,
  setRequestFinancingDateButton,
  setRequestInformationDate,
  setRequestViewingDate,
  setResetKaajaWidget,
  setSelectBigImage,
  setSelectImage,
  setShowLoginModal,
  setShowMakeOfferModal,
  setShowMissingInformationModal,
  setShowRequestAdviceButtonModal,
  setShowRequestFinancingModal,
  setShowRequestFreeSupportModal,
  setShowRequestInformationModal,
  setShowRequestViewingModal,
} from "@store/reducers/propertyDetails";
import { useMemo } from "react";
import { useDispatch } from "react-redux";

export default function usePropertyDetailsDispatchers() {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      handleLoading: (toggle) => dispatch(setLoading(toggle)),
      handleLoadPropertyDetails: (payload) => dispatch(onLoadPropertyDetails(payload)),
      handleShowRequestAdviceButtonModal: (toggle) => dispatch(setShowRequestAdviceButtonModal(toggle)),
      handleShowLoginModal: (toggle) => dispatch(setShowLoginModal(toggle)),
      handleShowRequestViewingModal: (toggle) => dispatch(setShowRequestViewingModal(toggle)),
      handleShowRequestInformationModal: (toggle) => dispatch(setShowRequestInformationModal(toggle)),
      handleShowMakeOfferModal: (toggle) => dispatch(setShowMakeOfferModal(toggle)),
      handleShowMissingInformationModal: (toggle) => dispatch(setShowMissingInformationModal(toggle)),
      handleShowRequestFinancingModal: (toggle) => dispatch(setShowRequestFinancingModal(toggle)),
      handleShowRequestFreeSupportModal: (toggle) => dispatch(setShowRequestFreeSupportModal(toggle)),
      handleMessage: (newMessage) => dispatch(setMessage(newMessage)),
      handleRequestInformationDate: (date) => dispatch(setRequestInformationDate(date)),
      handleRequestViewingDate: (date) => dispatch(setRequestViewingDate(date)),
      handleMakeOfferDate: (date) => dispatch(setMakeOfferDate(date)),
      handleRequestFinancingDateButton: (toggle) => dispatch(setRequestFinancingDateButton(toggle)),
      handleSelectImage: (img) => dispatch(setSelectImage(img)),
      handleSelectBigImage: (img) => dispatch(setSelectBigImage(img)),
      handleKaajaWidgetUrl: (url) => dispatch(setKaajaWidgetUrl(url)),
      handleResetKaajaWidget: () => dispatch(setResetKaajaWidget()),
      handleReset: () => dispatch(resetState()),
    }),
    [dispatch]
  );
}
