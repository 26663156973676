import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { isBrowser } from "react-device-detect";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import styled from "styled-components";

const StyledSlide = styled(Slide)`
  .focusRing___1airF.carousel__slide-focus-ring {
    outline: none !important;
  }
`;

function Carousel({ imagesList, displayPicsBigMode }) {
  return (
    <div style={{ position: "relative" }}>
      <CarouselProvider
        className="no-underline border-0 outline-none"
        naturalSlideWidth={100}
        isIntrinsicHeight
        naturalSlideHeight={125}
        totalSlides={imagesList?.length}
        visibleSlides={3}
        infinite
        interval={5000}
        isPlaying={false}
        dragEnabled={false}
        playDirection="forward"
        // onDrag={(e)=>{e.preventDefault()}}
      >
        <Slider>
          {imagesList.map((item, index) => (
            <StyledSlide key={item} index={index}>
              <div className="flex justify-center">
                <img
                  src={item}
                  alt="Loading..."
                  className="md:w-60 max-h-36 xl:w-48 2xl:w-60  h-auto object-cover cursor-pointer w-24 fix-img-size"
                  id={`slide-image${index}`}
                  onClick={() => displayPicsBigMode(index, item)}
                />
              </div>
            </StyledSlide>
          ))}
        </Slider>
        {imagesList.length > 3 && isBrowser && (
          <>
            <ButtonBack
              className="h-10 w-10 absolute flex items-center justify-center py-1 px-1 
              bg-white text-2xl rounded-full cursor-pointer"
              style={{
                position: "absolute",
                top: "35%",
                left: 0,
                background: "green",
                color: "white",
              }}
            >
              <AiOutlineCaretLeft />
            </ButtonBack>
            <ButtonNext
              className="h-10 w-10 absolute flex items-center justify-center py-1 px-1 
              bg-white text-2xl rounded-full cursor-pointer"
              style={{
                position: "absolute",
                top: "35%",
                right: 0,
                background: "green",
                color: "white",
              }}
            >
              <AiOutlineCaretRight />
            </ButtonNext>
          </>
        )}
      </CarouselProvider>
    </div>
  );
}

export default Carousel;
