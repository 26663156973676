import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Description = ({ description, pathName, query }) => {
  const { t } = useTranslation();

  return description && description.length > 250 ? (
    <>
      <span className="truncate-description">{description.substring(0, 250)}</span>
      <div className="text-right font-bold">
        <Link
          className="underline text-greenColor cursor-pointer ml-2 cursor"
          to={{
            pathname: pathName,
            state: query ? { referrer: query } : {},
          }}
        >
          {t("read_more.link")}
        </Link>
      </div>
    </>
  ) : (
    description
  );
};

export default Description;
