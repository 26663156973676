import Toastify from "@components/common/Toastify";
import { PROPERTY_TYPE_CODES } from "@constants";
import { APIReduxCaller } from "./ApiReduxRequest";

export async function GetPropertyTypeCodes(headers) {
  try {
    const data = await APIReduxCaller(PROPERTY_TYPE_CODES, null, headers, { method: "GET" });
    if (data?.error || data?.status > 399) {
      Toastify(data?.error, "error");
    }
    return data;
  } catch (err) {
    if (err?.error || err?.status > 399) {
      Toastify(err?.error, "error");
      return "";
    }
    throw err;
  }
}
