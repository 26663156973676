/* eslint-disable no-nested-ternary */
import { useAuthState } from "@store/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { PropInfoIcons } from "./PropInfoIcons";
import { NavigationControl, PropertyImage, PropertyPrice, PropertyTitle } from "./components";

function PropCard({ data, onCrossPropCardClick, inCourtAuction }) {
  const [showCard, setShowCard] = useState(false);
  const [index, setIndex] = useState(0);

  const { languageToTranslate } = useAuthState(({ languageToTranslate }) => ({
    languageToTranslate,
  }));
  const navigate = useNavigate();
  const query = window.location.search;

  useEffect(() => {
    if (data?.length > 0) {
      setShowCard(true);
    }
  }, [data]);

  //! high-order function to handle property navigation and event propagation
  const changeProperty = (increment) => (event) => {
    if (event.stopPropagation) event.stopPropagation();
    setIndex((index) => {
      const newIndex = index + increment;
      const { length } = data;
      return ((newIndex % length) + length) % length;
    });
  };

  const changePropertyForward = changeProperty(1);
  const changePropertyBackward = changeProperty(-1);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      const directionFunctions = {
        Left: changePropertyForward,
        Right: changePropertyBackward,
      };
      directionFunctions[eventData.dir]?.(eventData);
    },
  });

  // eslint-disable-next-line
  return (
    showCard && (
      <div
        {...handlers}
        className="bg-white shadow-xl cursor-pointer"
        onClick={() => {
          onCrossPropCardClick();
          navigate(
            data[index].in_court_auction
              ? `/property-details/${data[index].id}`
              : `/property-details-sale/${data[index].id}`,
            { state: { referrer: query } }
          );
        }}
      >
        <div className="flex flex-col" style={{ width: "100%" }}>
          <div
            onClick={() => {
              onCrossPropCardClick();
            }}
            style={{ width: "100%" }}
          >
            <PropertyImage
              src={data[index]?.main_photo_thumb_full_url}
              assetStateTag={data[index]?.translated_display_status}
              onCrossPropCardClick={onCrossPropCardClick}
              inCourtAuction={inCourtAuction}
              courtAuctionDate={data[index]?.court_auction?.date}
            />
            {data.length > 1 && (
              <NavigationControl
                changePropertyForward={changePropertyForward}
                changePropertyBackward={changePropertyBackward}
                morePropertiesNumber={`${index + 1}/${data.length}`}
              />
            )}
            <PropertyTitle
              languageToTranslate={languageToTranslate}
              titleLc={data[index]?.title_lc}
              titleEn={data[index]?.title_en}
            />
            <PropertyPrice
              inCourtAuction={inCourtAuction}
              listingPrice={data[index]?.listing_price}
              courtAuction={data[index]?.court_auction}
            />
          </div>
        </div>
        <div
          className="flex flex-row text-xs lg:datas-end lg:text-base"
          onClick={() => {
            onCrossPropCardClick();
          }}
        >
          <PropInfoIcons {...data[index].property} />
        </div>
      </div>
    )
  );
}

export default PropCard;
