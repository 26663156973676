const Icon = ({ action, className, stroke, color, number, children, badge }) => {
  return (
    <div
      className="relative cursor-pointer"
      onClick={() => {
        if (action) {
          action();
        }
      }}
    >
      <svg
        className={className || `h-6 w-6`}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth={stroke || 1}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        {children}
      </svg>
      {badge && (
        <div
          className={` ${!color ? "bg-primary" : color} top-3 z-20 left-3  w-4 h-4  p-2 absolute rounded-full text-xxs 
            flex items-center justify-center text-white text-center  `}
        >
          {number}
        </div>
      )}
    </div>
  );
};

export default Icon;
