import { useAuthState } from "@store/hooks";
import ReCAPTCHA from "react-google-recaptcha";

const GoogleCaptcha = ({ recaptchaChecked }) => {
  const { languageToTranslate } = useAuthState(({ languageToTranslate }) => ({
    languageToTranslate,
  }));

  return (
    <>
      {languageToTranslate === "it" ? (
        <ReCAPTCHA sitekey={process.env.REACT_APP_API_GOOGLE_SITE_KEY_CAPTCHA} onChange={recaptchaChecked} hl="it" />
      ) : null}

      {languageToTranslate === "en" ? (
        <ReCAPTCHA sitekey={process.env.REACT_APP_API_GOOGLE_SITE_KEY_CAPTCHA} onChange={recaptchaChecked} hl="en" />
      ) : null}

      {/* <ReCAPTCHA
        sitekey={process.env.REACT_APP_API_GOOGLE_SITE_KEY_CAPTCHA}
        onChange={recaptchaChecked}
        hl="en"
      /> */}
    </>
  );
};

export default GoogleCaptcha;
