import Icon from "@components/common/Icon";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const NavBarMobileItem = ({ path, classes, menuTitle, icon: LoadedIcon, handleClick }) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div
      onClick={handleClick}
      className={`flex justify-start gap-2 items-center py-2 px-4 border-gray-400 ${classes} ${
        location?.pathname === path ? "text-green-700" : ""
      } hover:text-green-700 transition duration-150`}
    >
      <Icon className="w-5 h-5" stroke="2">
        <LoadedIcon />
      </Icon>
      <span className="whitespace-nowrap">{t(menuTitle).charAt(0) + t(menuTitle).slice(1).toLowerCase()}</span>
    </div>
  );
};

export default NavBarMobileItem;
