import {
  BUY_WITH_US,
  CONFIRMATION_EMAIL_PAGE_PATH,
  CONTACT,
  COOKIE_POLICY,
  EDIT_PROFILE_URL,
  FORGOT_PASSWORD,
  HOME,
  LOGIN,
  MY_AREA,
  MY_OFFER,
  PROPERTY_DETAILS,
  PROPERTY_DETAILS_SALE,
  PROPERTY_SEARCH,
  PROPERTY_SEARCH_ASTA,
  RECLAMI,
  REGISTER,
  REQUEST_FREE_SUPPORT,
  RESET_PASSWORD_URL,
  SUPPORT,
  WATCH_LIST,
  WHO_WE_ARE,
} from "@constants/paths";

const routes = [
  {
    uniqueKey: "my-area",
    path: MY_AREA,
    restricted: false,
    privateRoute: true,
    componentPath: "pages/myArea",
  },
  {
    uniqueKey: "property-search",
    path: PROPERTY_SEARCH,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/searchProperties",
  },
  {
    uniqueKey: "property-search-asta",
    path: PROPERTY_SEARCH_ASTA,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/searchProperties",
  },
  {
    uniqueKey: "property-details",
    path: PROPERTY_DETAILS,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/propertyDetails",
  },
  {
    uniqueKey: "register",
    path: REGISTER,
    restricted: true,
    privateRoute: false,
    componentPath: "pages/register",
  },
  {
    uniqueKey: "reset-password",
    path: RESET_PASSWORD_URL,
    restricted: true,
    privateRoute: false,
    componentPath: "pages/resetPassword",
  },
  {
    uniqueKey: "edit-profile",
    path: EDIT_PROFILE_URL,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/editProfile",
  },
  {
    uniqueKey: "forget-password",
    path: FORGOT_PASSWORD,
    restricted: true,
    privateRoute: false,
    componentPath: "pages/forgetPassword",
  },
  {
    uniqueKey: "login",
    path: LOGIN,
    restricted: true,
    privateRoute: false,
    componentPath: "pages/login",
  },
  {
    uniqueKey: "buy-with-us",
    path: BUY_WITH_US,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/howItWork",
  },
  {
    uniqueKey: "confirmation-email",
    path: CONFIRMATION_EMAIL_PAGE_PATH,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/confirmationEmaiPage",
  },
  {
    uniqueKey: "who-we-are",
    path: WHO_WE_ARE,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/whoWeAre",
  },
  {
    uniqueKey: "contact",
    path: CONTACT,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/contact",
  },
  {
    uniqueKey: "my-offer",
    path: MY_OFFER,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/myOffer",
  },
  {
    uniqueKey: "property-details-sale",
    path: PROPERTY_DETAILS_SALE,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/propertyDetails",
  },
  {
    uniqueKey: "watch-list",
    path: WATCH_LIST,
    restricted: false,
    privateRoute: true,
    componentPath: "pages/watchList",
  },
  {
    uniqueKey: "home",
    path: HOME,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/homepage/Home",
  },
  {
    uniqueKey: "complaints",
    path: RECLAMI,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/complaints",
  },
  {
    uniqueKey: "cookie-policy",
    path: COOKIE_POLICY,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/cookiePolicy",
  },
  {
    uniqueKey: "request-free-support",
    path: REQUEST_FREE_SUPPORT,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/requestFreeSupport",
  },
  {
    uniqueKey: "support",
    path: SUPPORT,
    restricted: false,
    privateRoute: false,
    componentPath: "pages/support",
  },
  {
    uniqueKey: "not-found",
    path: "*",
    restricted: false,
    privateRoute: false,
    componentPath: "pages/notFound/NotFound",
  },
];

export default routes;
