import i18n from "i18next";
import { toast } from "react-toastify";

const LazyRetry = (componentImport, name) => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(sessionStorage.getItem(`retry-${name}-refreshed`) || "false");
    componentImport()
      .then((component) => {
        sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true"); // we are now going to refresh
          toast(
            <div>
              <p className="mb-2">{i18n.t("chunkLoadError.message")}</p>
              <div className="flex align-center justify-center">
                <button
                  type="button"
                  className="font-bnnp-bold w-16 mt-1 h-8 bg-bgDarakGreen font-bold 
                  uppercase tracking-widest text-md text-white"
                  onClick={() => window.location.reload()}
                >
                  {i18n.t("chunkLoadError.button")}
                </button>
              </div>
            </div>,
            {
              position: "top-right",
              hideProgressBar: true,
              toastId: "toastId",
              closeOnClick: true,
              autoClose: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            }
          );
          return;
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export default LazyRetry;
