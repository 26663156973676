import HrLine from "@components/common/HrLine";

const IndexWrapper =
  (Component) =>
  ({ ...props }) => {
    return (
      <div>
        <div className="min-full-height px-3">
          <Component {...props} />
        </div>
        <HrLine />
      </div>
    );
  };

export default IndexWrapper;
