/* eslint-disable import/no-unresolved */
import i18n from "i18next";
// english
import localesEN from "locales/en/locales.yml";
// italian
import localesIT from "locales/it/locales.yml";
import { initReactI18next } from "react-i18next";

const storedLang = localStorage.getItem("language") ?? "it";

const translations = {
  en: {
    default: localesEN,
  },
  it: {
    default: localesIT,
  },
};

i18n.use(initReactI18next).init({
  resources: translations,
  storedLang,
  wait: true,
  fallbackLng: storedLang,
  ns: ["default"],
  whitelist: ["en", "it"],
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
  returnEmptyString: true,
});
