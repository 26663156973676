import { bed, buildArea, plotArea, residential, rooms, unitNumber } from "@components/images";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PropertyDetails = ({ details, type }) => {
  const [propDetails, setPropDetails] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    const propType = type
      ? {
          img: residential,
          alt: "Residential",
          title: t("property.property_type"),
          primaryValue: type,
        }
      : null;

    if (details?.build_area > 0) {
      const propBuildArea = {
        img: buildArea,
        alt: "Build Area",
        title: t("property.build_area"),
        primaryValue: String(details.build_area).replace(".", ","),
        secondaryValue: t("square_meters"),
      };

      if (details?.units > 0) {
        const secondaryProp = {
          img: unitNumber,
          alt: "Unit Number",
          title: t("property.units"),
          primaryValue: details.units,
        };
        setPropDetails([propType, propBuildArea, secondaryProp]);
      } else if (details?.bedrooms > 0) {
        const secondaryProp = {
          img: bed,
          alt: "Bed",
          title: t("property.bedrooms"),
          primaryValue: details.bedrooms,
        };
        setPropDetails([propType, propBuildArea, secondaryProp]);
      } else if (details?.rooms > 0) {
        const secondaryProp = {
          img: rooms,
          alt: "Rooms",
          title: t("property.rooms"),
          primaryValue: details.rooms,
        };
        setPropDetails([propType, propBuildArea, secondaryProp]);
      } else {
        setPropDetails([propType, propBuildArea]);
      }
    } else if (details?.plot_area > 0) {
      const propPlotArea = {
        img: plotArea,
        alt: "Plot Area",
        title: t("property.plot_area"),
        primaryValue: String(details.plot_area).replace(".", ","),
        secondaryValue: t("square_meters"),
      };
      setPropDetails([propType, propPlotArea]);
    } else {
      setPropDetails([propType]);
    }
  }, []);

  return (
    <ul className="font-sans assets-icons">
      {propDetails?.length > 0
        ? propDetails.map((detail) => (
            // eslint-disable-next-line react/jsx-indent
            <li key={detail?.alt} className="flex mb-2 items-center">
              <img src={detail?.img} alt={detail?.alt} className="mr-4 w-6 h-6" />
              <p>
                {detail?.title} : <span className="text-greenColor">{detail?.primaryValue}</span>
                {` `} {detail?.secondaryValue}
              </p>
            </li>
          ))
        : null}
    </ul>
  );
};

export default PropertyDetails;
