import Toastify from "@components/common/Toastify";
import { PROPERTY_DETAILS_PAGE } from "@constants";
import { APIReduxCaller } from "./ApiReduxRequest";

export async function GetPropertyDetails(payLoad, headers) {
  try {
    const data = await APIReduxCaller(PROPERTY_DETAILS_PAGE, payLoad, headers);
    if (data?.error || data?.status > 399) {
      Toastify(data?.error, "error");
    }
    return data;
  } catch (err) {
    if (err?.error || err?.status > 399) {
      Toastify(err?.error, "error");
      return "";
    }
    throw err;
  }
}
