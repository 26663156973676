import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { RouterProvider } from "react-router-dom";

import router from "./routes";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://448a1c8dc83344aa92ab2eb230884570@o1158184.ingest.sentry.io/6738286",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
  });

  const tagManagerArgs = {
    gtmId: "GTM-TB2TZF8",
  };

  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<RouterProvider router={router} />);
