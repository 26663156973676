import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { onLoadPropertyTypeCodes } from "../reducers/propertyTypesCodes";

export default function usePropertyTypesCodesDispatchers() {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      handleLoadPropertyTypeCodes: () => dispatch(onLoadPropertyTypeCodes()),
    }),
    [dispatch]
  );
}
