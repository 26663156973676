const KaajaLogo = () => (
  <svg
    width="52"
    height="60"
    className="h-12 w-8 md:h-14 md:w-12"
    viewBox="0 0 30 30"
    strokeWidth={1}
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.46 17.091 13.873 30.47c.563.562 1.536.562 2.15 0l13.411-13.378c.614-.561.614-1.532 0-2.144L16.022 
      1.569c-.563-.613-1.536-.613-2.15 0L.46 14.947c-.615.562-.615 1.532 0 2.144Zm7.811 
      6.335 14.252.028V8.44H8.271a.596.596 0 0 0-.584.597v13.793c0 .325.265.597.584.597Z"
        fill="#2824E5"
      />
      <path
        d="m21.719 23.097-6.516-6.372a.985.985 0 0 1 0-1.43l6.552-6.536c.41-.408 1.074-.436 1.483-.027l6.167 
      6.167c.615.612.715 1.52 0 2.234l-6.077 6.053c-.41.409-1.162.358-1.61-.09Z"
        fill="#03005E"
      />
    </g>
  </svg>
);

export default KaajaLogo;
