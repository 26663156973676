import { Navigate } from "react-router-dom";
import { EDIT_PROFILE_URL, MESSAGES, MY_AREA, MY_DOCS, REQUESTS_URL, WATCH_LIST } from "../constants";

const NavigateToRequest = () => <Navigate to={MY_AREA + REQUESTS_URL} />;

const routes = [
  {
    uniqueKey: "my-area",
    path: MY_AREA,
    restricted: false,
    privateRoute: true,
    component: NavigateToRequest,
  },
  {
    uniqueKey: "messages",
    path: MY_AREA + MESSAGES,
    restricted: false,
    privateRoute: true,
    componentPath: "pages/myArea/pages/messages",
  },
  {
    uniqueKey: "my-docs",
    path: MY_AREA + MY_DOCS,
    restricted: false,
    privateRoute: true,
    componentPath: "pages/myArea/pages/myDocs",
  },
  {
    uniqueKey: "edit-profile",
    path: MY_AREA + EDIT_PROFILE_URL,
    restricted: false,
    privateRoute: true,
    componentPath: "pages/myArea/pages/editProfile",
  },
  {
    uniqueKey: "requests",
    path: MY_AREA + REQUESTS_URL,
    restricted: false,
    privateRoute: true,
    componentPath: "pages/myArea/pages/requests",
  },
  {
    uniqueKey: "watch-list",
    path: MY_AREA + WATCH_LIST,
    restricted: false,
    privateRoute: true,
    componentPath: "pages/myArea/pages/watchList",
  },
];

export default routes;
