/* eslint-disable react/no-danger */
import { AuthVerify, Loader } from "@components/common";
import { ENVIRONMENT } from "@constants";
import useTokenExpired from "@hooks/useTokenExpired";
import { StrictMode, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import ScrollToTop from "./ScrollToTop";
import "./i18n";
import Layout from "./layout/Layout";
import store from "./store/store";
import "./styles/index.css";

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <StrictMode>
        <Provider store={store}>
          <Main />
        </Provider>
      </StrictMode>
    </Suspense>
  );
}

const Main = () => {
  const { onAutoLogout } = useTokenExpired();
  return (
    <>
      {ENVIRONMENT === "production" ? (
        <Helmet>
          <script
            type="text/javascript"
            src="https://cdn.cookielaw.org/consent/23c1d24b-0f5a-42fc-8dca-a671ecc4870e/OtAutoBlock.js"
          />
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script="23c1d24b-0f5a-42fc-8dca-a671ecc4870e"
          />

          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: "function OptanonWrapper() {}",
            }}
          />
        </Helmet>
      ) : null}
      <ScrollToTop />
      <Layout />
      <AuthVerify logOut={onAutoLogout} />
    </>
  );
};

export default App;
