import { issueRequest } from "./helpers/issueRequest";

export function APIReduxCaller(endpoint, body, headers = {}, options = {}) {
  const descriptor = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    body: body && JSON.stringify(body),
  };
  return issueRequest(endpoint, { ...descriptor, ...options });
}
