const areDaysDiffPriorToAuction = (courtAuctionDate, hasReportAttachment) => {
  const today = new Date();
  const date = new Date(courtAuctionDate);
  const diffInMs = date - today;
  const daysDiff = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

  return {
    displayDownloadReport: hasReportAttachment && daysDiff >= 0,
    displayAdviceBanner: !hasReportAttachment && daysDiff > 30,
    displayRequestAdvice: daysDiff > -1,
    daysDiff,
  };
};
export default areDaysDiffPriorToAuction;
