export const USER_REGISTER = "users/sign_up";
export const USER_LOGIN = "users/authenticate";
export const EDIT_PROFILE = "users/edit_profile";
export const GET_USER = "users/me";
export const GET_LOCALE = "users/set_locale";
export const EMAIL_CONFIRMATION = "users/email_confirm";
export const FORGET_PASSWORD = "users/forgot_password";
export const RESEND_CONFIRMATION_EMAIL = "users/resend_confirmation_email";
export const RESET_PASSWORD = "users/reset_password";
export const HOME_SLIDER = "assets/featured.json";
export const SEARCH_ASSETS = "assets";
export const MAP_ASSETS = "asset_map";
export const PROPERTY_DETAILS_PAGE = "asset_details";
export const INFORMATION_REQUEST = "create_information_request";
export const REQUEST_VIEWING = "create_viewing_request";
export const ADD_OR_REMOVE_WATCHLIST = "create_or_delete_from_watchlist";
export const WATCHLIST = "watchlists_page";
export const DOCUMENTS = "documents";
export const REQUEST_MORTGAGE = "create_financing_request";
export const CREATE_OFFER = "create_offer";
export const UPDATE_OFFER = "update_offer";
export const GET_REGION_PROVINCES = "get_region_provinces";
export const PROPERTY_TYPE_CODES = "property_types";
export const USER_CHATS = "user_chats";
export const USER_CHAT_MESSAGES = "user_chat_messages";
export const USER_CHAT_ATTACHMENTS = "user_chat_attachments";
export const USER_REQUESTS = "user_requests";
export const LOCATIONS = "locations";
export const ADVICE_REQUESTS = "advice_requests";
