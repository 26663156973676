import { useAuthState } from "@store/hooks";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ restricted, privateRoute, children }) => {
  const { isLoggedIn } = useAuthState((state) => state);
  const location = useLocation();
  if (privateRoute) {
    return !isLoggedIn ? <Navigate to="/login" state={{ from: location }} replace /> : children;
  }

  return isLoggedIn && restricted ? <Navigate to="/" state={{ from: location }} replace /> : children;
};

export default ProtectedRoute;
