import { useEffect, useRef } from "react";

/**
 * Component that alerts if you click outside of it
 */
const OutsideAlerter = ({ children, styles, clickOutside }) => {
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef?.current?.contains(event.target)) {
      clickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles} ref={wrapperRef}>
      {children}
    </div>
  );
};

export default OutsideAlerter;
