import { missingPhoto } from "@components/images";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getReduxHeaders from "@store/helpers/getReduxHeaders";

export const initialPropertyDetailsState = {
  loading: false,
  modals: {
    showRequestAdviceButtonModal: false,
    showLoginModal: false,
    showRequestViewingModal: false,
    showRequestInformationModal: false,
    showMakeOfferModal: false,
    showMissingInformationModal: false,
    showRequestFinancingModal: false,
    showRequestFreeSupportModal: false,
  },
  data: {},
  images: {
    imagesList: undefined,
    selectImage: missingPhoto,
    selectBigImage: undefined,
  },
  dates: {
    requestInformationDate: null,
    requestViewingDate: null,
    makeOfferDate: null,
  },
  requestFinancingDateButton: null,
  message: "",
  kaajaWidget: {
    enabled: false,
    url: "",
  },
};

export const onLoadPropertyDetails = createAsyncThunk(
  "propertyDetails/onLoadPropertyDetails",
  async ({ payLoad }, { rejectWithValue, extra, getState }) => {
    const headers = getReduxHeaders(getState);
    try {
      const res = await extra.GetPropertyDetails(payLoad, headers);
      return { res };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const propertyDetailsSlice = createSlice({
  name: "propertyDetails",
  initialState: initialPropertyDetailsState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShowRequestAdviceButtonModal: (state, action) => {
      state.modals.showRequestAdviceButtonModal = action.payload;
    },
    setShowLoginModal: (state, action) => {
      state.modals.showLoginModal = action.payload;
    },
    setShowRequestViewingModal: (state, action) => {
      state.modals.showRequestViewingModal = action.payload;
    },
    setShowRequestInformationModal: (state, action) => {
      state.modals.showRequestInformationModal = action.payload;
    },
    setShowMakeOfferModal: (state, action) => {
      state.modals.showMakeOfferModal = action.payload;
    },
    setShowMissingInformationModal: (state, action) => {
      state.modals.showMissingInformationModal = action.payload;
    },
    setShowRequestFinancingModal: (state, action) => {
      state.modals.showRequestFinancingModal = action.payload;
    },
    setShowRequestFreeSupportModal: (state, action) => {
      state.modals.showRequestFreeSupportModal = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setRequestInformationDate: (state, action) => {
      state.dates.requestInformationDate = action.payload;
    },
    setRequestViewingDate: (state, action) => {
      state.dates.requestViewingDate = action.payload;
    },
    setMakeOfferDate: (state, action) => {
      state.dates.makeOfferDate = action.payload;
    },
    setRequestFinancingDateButton: (state, action) => {
      state.requestFinancingDateButton = action.payload;
    },
    setSelectImage: (state, action) => {
      state.images.selectImage = action.payload;
    },
    setSelectBigImage: (state, action) => {
      state.images.selectBigImage = action.payload;
    },
    setKaajaWidgetUrl: (state, action) => {
      state.kaajaWidget.enabled = true;
      state.kaajaWidget.url = action.payload;
    },
    setResetKaajaWidget: (state) => {
      state.kaajaWidget.enabled = false;
      state.kaajaWidget.url = "";
    },
    resetState: () => {
      return { ...initialPropertyDetailsState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onLoadPropertyDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(onLoadPropertyDetails.fulfilled, (state, action) => {
        const { res } = action.payload;
        const assets = { ...res?.assets };
        if (assets?.related_photos_thumb_url.length) {
          assets.related_photos_thumb_url = [assets.main_photo_thumb_full_url, ...assets.related_photos_thumb_url];
          assets.related_photos_url =
            assets?.related_photos_url?.length > 0
              ? [assets.main_photo_thumb_full_url, ...assets.related_photos_url]
              : [assets.main_photo_thumb_full_url];
        }
        const images =
          assets?.related_photos_url?.length > 0
            ? [assets?.main_photo_full_url, ...assets.related_photos_url.filter((_, idx) => idx > 0)]
            : [assets?.main_photo_full_url];
        state.data = assets;
        state.images.imagesList = images;
        state.images.selectImage = res?.assets?.main_photo_medium_full_url;
        state.images.selectBigImage = res?.assets?.main_photo_full_url;
        state.dates = {
          requestInformationDate:
            res?.latest_can_request_information_of_user?.created_at ||
            initialPropertyDetailsState.dates.requestInformationDate,
          requestViewingDate:
            res?.latest_viewing_request_of_user?.created_at || initialPropertyDetailsState.dates.requestViewingDate,
          makeOfferDate: res?.latest_offer_of_user?.created_at || initialPropertyDetailsState.dates.makeOfferDate,
        };
        state.requestFinancingDateButton = res?.latest_financing_request_of_user?.created_at;
        state.loading = false;
      })
      .addCase(onLoadPropertyDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});

const { actions, reducer } = propertyDetailsSlice;

export const {
  setLoading,
  setShowRequestAdviceButtonModal,
  setShowLoginModal,
  setShowRequestViewingModal,
  setShowRequestInformationModal,
  setShowMakeOfferModal,
  setShowMissingInformationModal,
  setShowRequestFinancingModal,
  setShowRequestFreeSupportModal,
  setMessage,
  setRequestInformationDate,
  setRequestViewingDate,
  setMakeOfferDate,
  setRequestFinancingDateButton,
  setSelectImage,
  setSelectBigImage,
  setKaajaWidgetUrl,
  setResetKaajaWidget,
  resetState,
} = actions;

export default reducer;
