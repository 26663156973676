import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CopyRight from "./components/CopyRight";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

import { ENVIRONMENT } from "../constants";

const Layout = () => {
  return (
    <div id="enerinvest-root" data-testid="enerinvest-layout-root" data-environment={ENVIRONMENT}>
      <Navbar />
      <Outlet />
      <Footer />
      <CopyRight />
      <ToastContainer stacked className="top-24" />
    </div>
  );
};

export default Layout;
